import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';

import { HttpClient } from '@angular/common/http';
import ObjectID from 'bson-objectid';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthUser } from '../../../auth/model/AuthUser';
import { CustomHeaderTemplate, GridColumn, GridConfig, IGridColumn } from '../../../core/grid';
import { MinMaxColumns } from '../../../core/grid/sub-grid-update';
import { Pointer } from '../../../core/model/pointer';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MeasurementTest } from '../../../settings-modules/assessment-types/model/Measurement';
import { MachineAssessmentExamination } from '../../machine-services/model/machine-assessment-examination';
import {
  MachineGridMeasurement,
  MachineGridMeasurementData,
  MachineGridMeasurementDto,
  MeasurementEntryData,
  MeasurementLimitDto
} from '../../machine-services/model/machine-grid-measurement';
import { MachineMeasurement } from '../../machine-services/model/machine-measurement';
import { MeasurementValue } from '../../machine-services/model/measurement-value';
import { ProjectMachine } from '../../machine-services/model/project-machine';

@Injectable()
export class MachineMeasurementsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;
  private measurementTests: Pointer[];

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineMeasurementsService');
  }

  public setMeasurementTests(measurementTests: Pointer[]): void {
    this.measurementTests = measurementTests;
  }

  public setProject(projectId: string): this {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): this {
    this.machineId = machineId;
    return this;
  }

  public getMeasurements(filter?: QueryFilter): Observable<QueryResult<MachineMeasurement>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting measurements for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Measurements'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineMeasurement>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No measurements found');
          return result;
        }

        this.logger.debug('measurements found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting measurements: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getMeasurementsList(filter?: QueryFilter): Observable<QueryResult<MachineGridMeasurement>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting measurements for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'MeasurementsList'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineGridMeasurement>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No measurements found');
          return result;
        }

        this.logger.debug('measurements found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting measurements: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public updateMeasurement(measurement: MachineGridMeasurementDto): Observable<MachineGridMeasurementDto> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating measurement {0} for machine {1} from project {2}', measurement.id, this.machineId, this.projectId);

    const url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Measurements', measurement.id].join('/');

    return this.http.put<MachineGridMeasurementDto>(url, measurement).pipe(
      tap((result) => {
        if (!result) {
          throw new Error('Updated measurement was not saved');
        }

        this.logger.debug('measurement updated');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error updating measurement: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public createMeasurement(measurement: MachineGridMeasurementDto): Observable<MachineGridMeasurementDto> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Creating measurement {0} for machine {1} from project {2}', measurement.id, this.machineId, this.projectId);

    const url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Measurements', measurement.id].join('/');

    return this.http.post<MachineGridMeasurementDto>(url, measurement).pipe(
      tap((result) => {
        if (!result) {
          throw new Error('Created measurement was not saved');
        }

        this.logger.debug('measurement created');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error creating measurement: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public deleteMeasurement(measurementId: string): Observable<void | never> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Deleting measurement {0} for machine {1} from project {2}', measurementId, this.machineId, this.projectId);

    const url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Measurements', measurementId].join('/');

    return this.http.delete<void | never>(url).pipe(
      tap((result) => {
        this.logger.debug('measurement deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting measurement: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public updateMeasurementLimits(examinationId: string, updateLimitDto: MeasurementLimitDto) {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Updating measurement limits {0} for examination {1} from project {2}',
      updateLimitDto.id,
      examinationId,
      this.projectId
    );

    const url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'examinations', examinationId, 'MeasurementLimit'].join(
      '/'
    );

    return this.http.put<MachineGridMeasurementDto>(url, updateLimitDto).pipe(
      tap((result) => {
        if (!result) {
          throw new Error('Measurement limits were not updated');
        }

        this.logger.debug('Measurement limits updated');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error updating measurement limits: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public importMeasurements(examinationId: string, file: File): Observable<MachineGridMeasurementDto> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Importing measurements for examination {0} from machine {1}', examinationId, this.machineId);

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'examinations', examinationId, 'ImportMeasurement'].join(
      '/'
    );

    return this.http.post<MachineGridMeasurementDto>(url, formData).pipe(
      catchError((err, caught) => {
        this.logger.error('Error importing measurements: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getMeasurementTests(machineMeasurements: MachineGridMeasurement[]): Pointer[] {
    const measurementTestIds: Set<string> = new Set();
    const measurementTests: Pointer[] = [];
    const measurementValues: MeasurementValue[] = machineMeasurements.reduce(
      (acc, cur) => [
        ...acc,
        ...cur.measurementEntries.reduce((accumulator, current) => [...accumulator, ...current.measurementValues], [])
      ],
      []
    );
    measurementValues.forEach(({ measurementTest }) => {
      if (!measurementTestIds.has(measurementTest.id)) {
        measurementTests.push(measurementTest);
      }
      measurementTestIds.add(measurementTest.id);
    });
    return measurementTests;
  }

  public getSubGridColumnsFromTests(tests: MeasurementTest[]): [GridColumn[], Pointer[]] {
    const measurementTestIds: Set<string> = new Set();
    const subGridColumns: GridColumn[] = [];
    const measurementTests: Pointer[] = [];
    tests.forEach((measurementTest) => {
      if (!measurementTestIds.has(measurementTest.test.id)) {
        // the test names below are not entered directly by the user there for editing is not allowed
        const isEditableTest = !(measurementTest.test.name === 'Temperature Rise' || measurementTest.test.name === 'Ambient Temperature');
        subGridColumns.push(
          GridColumn.create({
            dataField: measurementTest.test.id,
            captionKey: measurementTest.test.name,
            dataType: 'string',
            min: measurementTest.lowerLimit,
            max: measurementTest.upperLimit,
            definedByUser: measurementTest.definedByUser,
            customHeaderTemplate: CustomHeaderTemplate.minMax,
            forbidDecimalComma: true,
            allowEditing: isEditableTest
          })
        );
        measurementTests.push(measurementTest.test);
      }
      measurementTestIds.add(measurementTest.test.id);
    });
    subGridColumns.push(
      GridColumn.create({
        dataField: 'testDate',
        captionKey: 'Test Date',
        dataType: 'date',
        format: 'dd.MM.yyyy',
        required: true
      }),
      GridColumn.create({
        dataField: 'modifiedAt',
        captionKey: 'Updated At',
        dataType: 'datetime',
        format: 'dd.MM.yyyy HH:mm',
        allowEditing: false
      })
    );
    return [subGridColumns, measurementTests];
  }

  public getMeasurementTypeOptions(machineMeasurements: MachineGridMeasurement[]): Array<{ value: string; label: string }> {
    return machineMeasurements.map((machineMeasurement) => ({ value: machineMeasurement.type.id, label: machineMeasurement.type.name }));
  }

  public mapMeasurementsToGrid(
    machineGridMeasurements: MachineGridMeasurement[],
    currentUser: AuthUser,
    projectMachine?: ProjectMachine
  ): MachineGridMeasurementData[] {
    const checkpoints: MachineAssessmentExamination[] = projectMachine
      ? projectMachine.assessment.checklists.reduce(
          (acc, cur) => [...acc, ...cur.sections.reduce((accumulator, current) => [...accumulator, ...current.examinations], [])],
          []
        )
      : undefined;

    return machineGridMeasurements.map((machineGridMeasurement, index) => {
      const checkpoint = checkpoints ? checkpoints.find((cp) => cp.id === machineGridMeasurement.examinations.id) : undefined;
      const subGridColumns: GridColumn[] =
        machineGridMeasurement.measurementEntries.length > 0
          ? machineGridMeasurement.measurementEntries[0].measurementValues.map(({ measurementTest }) => {
              if (checkpoint) {
                return GridColumn.create({
                  dataField: measurementTest.id,
                  captionKey: measurementTest.name,
                  dataType: 'string',
                  min: checkpoint.measurement.tests.find((test) => test.test.id === measurementTest.id)?.lowerLimit,
                  max: checkpoint.measurement.tests.find((test) => test.test.id === measurementTest.id)?.upperLimit,
                  definedByUser: checkpoint.measurement.tests.find((test) => test.test.id === measurementTest.id)?.definedByUser,
                  customHeaderTemplate: CustomHeaderTemplate.minMax
                });
              }
              return GridColumn.create({
                dataField: measurementTest.id,
                captionKey: measurementTest.name,
                dataType: 'string'
              });
            })
          : [];
      subGridColumns.push(
        GridColumn.create({
          dataField: 'testDate',
          captionKey: 'Test Date',
          dataType: 'date',
          format: 'dd.MM.yyyy',
          required: true
        }),
        GridColumn.create({
          dataField: 'modifiedAt',
          captionKey: 'Updated At',
          dataType: 'datetime',
          format: 'dd.MM.yyyy HH:mm',
          allowEditing: false
        })
      );
      return {
        id: index.toString(), // only for grid
        measurementTypeName: machineGridMeasurement.type.name,
        engineerName: machineGridMeasurement.testEngineer.name,
        examinationNumber: machineGridMeasurement.examinationNumber ?? machineGridMeasurement.examinations['name'],
        equipmentReference: machineGridMeasurement.equipment['name'] ?? machineGridMeasurement.equipment['reference'],
        equipmentModel: machineGridMeasurement.equipment.model,
        assetNumber: machineGridMeasurement.equipment.assetNumber,
        calibration: machineGridMeasurement.equipment.calibration,
        machineGridMeasurement,
        measurementEntries: machineGridMeasurement.measurementEntries?.map(
          ({ measurementId, testDate, measurementValues, modifiedAt }) => ({
            id: ObjectID.generate(),
            measurementId,
            testDate,
            modifiedAt,
            ...measurementValues.reduce((acc, cur: MeasurementValue) => ({ ...acc, [cur.measurementTest.id]: cur.value }), {})
          })
        ),
        subGridConfig: GridConfig.create({
          keyExpr: 'id',
          gridActionsEnabled: currentUser.id === machineGridMeasurement.testEngineer.id
        }),
        subGridColumns,
        ...(!!checkpoint && { examination: checkpoint })
      } as MachineGridMeasurementData;
    });
  }

  public mapGridMeasurementToDto(
    measurementEntryData: MeasurementEntryData,
    mainGridMeasurementRow: MachineGridMeasurementData,
    update: boolean,
    measurementTests?: MeasurementTest[],
    columns?: IGridColumn[]
  ): MachineGridMeasurementDto {
    const measurementValues: MeasurementValue[] = [];
    if (measurementTests) {
      measurementTests.forEach((measurementTest) => {
        measurementValues.push({
          id: update ? measurementEntryData.measurementId : ObjectID.generate(),
          value: measurementEntryData[measurementTest.test.id] ? measurementEntryData[measurementTest.test.id].toString() : '',
          measurementTest: measurementTest.test
        });
      });
    } else {
      columns.forEach((column) => {
        if (column.dataField !== 'testDate' && column.dataField !== 'modifiedAt') {
          measurementValues.push({
            id: update ? measurementEntryData.measurementId : ObjectID.generate(),
            value: measurementEntryData[column.dataField] ? measurementEntryData[column.dataField].toString() : '',
            measurementTest: this.measurementTests.find(({ id }) => id === column.dataField)
          });
        }
      });
    }
    const data = {
      examinationsId: mainGridMeasurementRow.machineGridMeasurement.examinations.id,
      measurementType: mainGridMeasurementRow.machineGridMeasurement.type,
      engineer: mainGridMeasurementRow.machineGridMeasurement.testEngineer,
      testDate: measurementEntryData.testDate,
      equipmentId: mainGridMeasurementRow.machineGridMeasurement.equipment.id,
      calibration: mainGridMeasurementRow.calibration,
      status: mainGridMeasurementRow.machineGridMeasurement.status,
      measurementValues
    };
    return { ...data, ...(update && { id: measurementEntryData.measurementId }) };
  }

  public areMeasurementsValid(minMaxColumns: MinMaxColumns, measurementEntries: MeasurementEntryData[]): boolean {
    let valid = true;

    for (const measurementEntry of measurementEntries) {
      for (const [key, value] of Object.entries(measurementEntry)) {
        const convertedValue = Number(value);
        if (value === '' || Number.isNaN(convertedValue)) {
          continue;
        }
        const minMaxColumn = minMaxColumns[key];
        if (minMaxColumn) {
          if (minMaxColumn.min !== undefined) {
            valid = convertedValue >= minMaxColumn.min;
            if (!valid) {
              break;
            }
          }
          if (minMaxColumn.max !== undefined) {
            valid = convertedValue <= minMaxColumn.max;
            if (!valid) {
              break;
            }
          }
        }
      }
      if (!valid) {
        break;
      }
    }

    return valid;
  }
}
