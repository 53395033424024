import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FileWhitelist } from '../../../core-services/model/file-whitelist.enum';
import { Photo } from '../../../projects-modules/machine-services/model/photo';
import { UpdateUserProfileDto } from '../../model/user-profile-dto';
import { NotificationsService } from '../../notifications/shared/notifications.service';
import { QueryFilter } from '../../query-filter/model/QueryFilter';
import { QueryResult } from '../../query-filter/model/QueryResult';
import { QueryFilterHelper } from '../../query-filter/shared/query-filter-helper';
import { Form, FormService } from '../../shared/form.service';
import { ILogger, LoggerService } from '../../shared/logger.service';
import { MessageBrokerService } from '../../shared/message-broker.service';
import { SpinnerService } from '../../spinner/shared/spinner.service';
import { ProfileLaboratoryService } from '../services/profile-laboratory.service';
import { UserProfileService } from '../services/user-profile.service';
import { Laboratory } from './../../../settings-modules/laboratories/model/laboratory';

@Component({
  selector: 'app-user-profile-auto-save-panel',
  templateUrl: './user-profile-auto-save-panel.component.html',
  styleUrls: ['./user-profile-auto-save-panel.component.scss']
})
export class UserProfileAutoSavePanelComponent implements OnInit, OnDestroy {
  public laboratories: Array<Laboratory>;
  public filter: QueryFilter = new QueryFilter();
  public selectForm: Form;

  public names: string[] = [];
  queryResultLaboratory: QueryResult<Laboratory>;
  queryResultUserProfile: QueryResult<any>;

  savingLab = false;
  savingPhone = false;
  laboratoryId: string;
  phoneNumber: string;

  public displayStringFileName = '';

  public allowedMimeTypes: string[] = [
    FileWhitelist.extJpeg,
    FileWhitelist.extJpg,
    FileWhitelist.extGif,
    FileWhitelist.extPng
  ];
  private logger: ILogger;
  private subscriptions: Subscription[] = [];
  private refreshSubscription: Subscription;
  private signaturePhoto: Photo;

  constructor(
    loggerService: LoggerService,
    private profileLaboratoryService: ProfileLaboratoryService,
    private userProfileService: UserProfileService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private formService: FormService,
    private messageBroker: MessageBrokerService,
    private spinnerService: SpinnerService
  ) {
    this.logger = loggerService.getLogger('LabAutoSaveSelectComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    this.selectForm = this.formService.form({
      laboratoryId: [null],
      phoneNumber: [null],
      fileName: [null]
    });

    this.filter.ascending('name');
    this.filter.keys(['id', 'name']);
    this.filter.take(9999);

    this.subscriptions.push(
      this.messageBroker.from('laboratory.save').subscribe((_) => {
        this.refresh(true);
      })
    );

    this.refresh();
  }

  public ngOnDestroy(): void {
    this.logger.debug('Destroying component');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public refresh(preservePaging?: boolean): void {
    this.logger.debug('Refreshing laboratories');

    this.spinnerService.show('laboratories');
    this.laboratories = [];

    if (!preservePaging) {
      this.filter.from(0);
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.refreshSubscription = QueryFilterHelper.preservePaging(this.filter, () =>
      this.profileLaboratoryService.getLaboratories(this.filter)
    )
      .pipe(take(1))
      .subscribe(
        (query) => {
          this.filter.count = query.count;
          this.laboratories = query.data;
          this.queryResultLaboratory = query;

          if (this.names.length === 0) {
            this.names = [];
            this.laboratories.forEach((laboratory) => {
              if (laboratory.name && this.names.indexOf(laboratory.name) === -1) {
                this.names.push(laboratory.name);
              }
            });
          }
          this.logger.debug('names', this.names);

          this.userProfileService
            .getUserProfile()
            .pipe(take(1))
            .subscribe((queryResult: any) => {
              this.queryResultUserProfile = queryResult;

              if (queryResult) {
                this.logger.debug('userProfile', queryResult);

                if (queryResult.laboratory && queryResult.laboratory.id) {
                  this.laboratoryId = queryResult.laboratory.id;

                  this.selectForm.patchValue({
                    laboratoryId: this.laboratoryId
                  });
                }

                if (queryResult.phoneNumber) {
                  this.phoneNumber = queryResult.phoneNumber;

                  this.selectForm.patchValue({
                    phoneNumber: this.phoneNumber
                  });
                }

                if (queryResult.signaturePhoto) {
                  this.selectForm.patchValue({
                    fileName: queryResult.signaturePhoto.name
                  });
                  this.displayStringFileName = queryResult.signaturePhoto.name;
                  this.signaturePhoto = queryResult.signaturePhoto;
                  this.logger.debug('queryResult.signaturePhoto', queryResult.signaturePhoto);
                  this.logger.debug('this.signaturePhoto', this.signaturePhoto);
                }
              } else {
                this.logger.debug('No userProfile found');
              }
            });

          this.spinnerService.hide('laboratories');
        },
        (error) => {
          this.notificationsService.danger(this.translateService.instant('laboratories.list.notification.not-found'), error);

          this.spinnerService.hide('laboratories');
        }
      );
  }

  public notFound(): boolean {
    return !this.spinnerService.isShowing('laboratories') && this.laboratories && this.laboratories.length === 0;
  }

  onSubmit() {
    this.logger.debug('Method not implemented.');
  }

  onLabChange($event: any) {
    this.logger.debug('onChange $event', $event);
    this.savingLab = true;
    let labId: string;

    if ($event && $event.id) {
      this.logger.debug('Laboratory selected', $event.id);
      labId = $event.id;
    } else {
      this.logger.debug('Laboratory not selected');
      labId = '';
    }

    const userProfileDto = { laboratoryId: labId, phoneNumber: this.phoneNumber } as UpdateUserProfileDto;
    this.userProfileService
      .setUserProfile(userProfileDto)
      .pipe(take(1))
      .subscribe((userProfile) => {
        this.savingLab = false;
        this.logger.debug('userProfile', userProfile);
        if (userProfile) {
          this.notificationsService.success(this.translateService.instant('menu.user-profile.notification.save'), true);
          this.laboratoryId = labId;
        } else {
          this.notificationsService.danger(this.translateService.instant('menu.user-profile.notification.error-save'));
        }
      });
  }

  onPhoneNumberChange($event: any) {
    this.logger.debug('onPhoneNumberChange $event.target.value', $event.target.value);
    this.savingPhone = true;
    const userProfileDto = { phoneNumber: $event.target.value, laboratoryId: this.laboratoryId } as UpdateUserProfileDto;
    this.userProfileService
      .setUserProfile(userProfileDto)
      .pipe(take(1))
      .subscribe((userProfile) => {
        this.savingPhone = false;
        this.logger.debug('userProfile', userProfile);
        if (userProfile) {
          this.notificationsService.success(this.translateService.instant('menu.user-profile.notification.save'), true);
          this.phoneNumber = $event.phoneNumber;
        } else {
          this.notificationsService.danger(this.translateService.instant('menu.user-profile.notification.error-save'));
        }
      });
  }

  public filesSelected(files: FileList) {
    this.logger.debug('Files selected');

    if (files && files.length > 0) {
      const file = files[0];

      this.spinnerService.show('upload');

      this.userProfileService
        .setSignature(file)
        .pipe(
          take(1),
          finalize(() => {
            this.spinnerService.hide('upload');
          })
        )
        .subscribe(
          (_) => {
            this.logger.debug('Signature saved');
            this.notificationsService.success(this.translateService.instant('menu.user-profile.notification.signature-upload'), true);
            this.selectForm.get('fileName').patchValue(file.name);
            this.displayStringFileName = file.name;
          },
          (error) => {
            this.logger.error('Error saving signature', error);
            this.notificationsService.danger(this.translateService.instant('menu.user-profile.notification.signature-error'), error);
          }
        );
    }
  }

  public download(): void {
    this.logger.debug('Downloading file');

    this.userProfileService.getSignature();
  }

  public delete(): void {
    this.logger.debug('Deleting file', this.signaturePhoto);

    this.userProfileService
      .deleteSignature()
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.logger.debug('Signature deleted');
          this.notificationsService.success(this.translateService.instant('menu.user-profile.notification.signature-delete'), true);
          this.selectForm.get('fileName').patchValue(null);
          this.displayStringFileName = '';
        },
        (error) => {
          this.logger.error('Error deleting signature', error);
          this.notificationsService.danger(this.translateService.instant('menu.user-profile.notification.signature-delete-error'), error);
        }
      );
  }
}
