import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, concat, delay, filter, finalize, first, map, mapTo, retryWhen, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AppAnalytics } from '../../core/logging/shared/analytics';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { StartupService } from '../../core/shared/startup.service';
import { TranslationService } from '../../core/shared/translation.service';
import { UserNotificationsService } from '../../user-notifications/shared/user-notifications.service';
import { AuthRefreshComponent } from '../auth-refresh/auth-refresh.component';
import { AuthReloginComponent } from '../auth-relogin/auth-relogin.component';
import { NonceService } from '../internal/nonce.service';
import { TokenService } from '../internal/token.service';
import { AuthProvider } from '../model/AuthProvider';
import { AuthUser } from '../model/AuthUser';
import { Role } from '../model/Role';

@Injectable()
export class AuthService {
  public user: AuthUser;

  protected authOptions: { [key: string]: string };

  private readonly storageKeys: { [key: string]: string } = {
    existingUrl: 'mcomplus/url'
  };

  private authRefreshComponent: AuthRefreshComponent;
  private refreshTokenTimeout: any;
  private refreshTokenSubject: Subject<string> = new Subject<string>();
  private isRefreshingToken = false;
  private authReloginComponent: AuthReloginComponent;
  private reloginSubject: Subject<boolean> = new Subject<boolean>();
  private isRelogin = false;
  private logger: ILogger;
  private organizationStorageKey = 'mcomplus/auth/toggledOrganization';

  constructor(
    protected injector: Injector,
    protected tokenService: TokenService,
    protected startupService: StartupService,
    protected nonceService: NonceService,
    logger: LoggerService
  ) {
    this.authOptions = Object.assign({}, environment.authOptions);
    this.logger = logger.getLogger('AuthService');
  }

  protected get router(): Router {
    return this.injector.get(Router);
  }

  protected get translationService(): TranslationService {
    return this.injector.get(TranslationService);
  }

  private get userNotificationsService(): UserNotificationsService {
    return this.injector.get(UserNotificationsService);
  }

  public registerAuthRefreshComponent(authRefreshComponent: AuthRefreshComponent): void {
    this.logger.debug('Registered AuthRefreshComponent');
    this.authRefreshComponent = authRefreshComponent;
  }

  public registerAuthReloginComponent(authReloginComponent: AuthReloginComponent): void {
    this.logger.debug('Registered AuthReloginComponent');
    this.authReloginComponent = authReloginComponent;
  }

  public refreshIdentityToken(): Observable<string> {
    if (!this.isRefreshingToken) {
      this.logger.debug('Refreshing identity token silently');

      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.refreshTokenSubject = new Subject<string>();

      const params = new URLSearchParams();
      params.set('client_id', this.authOptions.clientId);
      params.set('scope', 'openid');
      params.set('redirect_uri', this.authOptions.redirectSilentUrl);
      params.set('response_type', 'id_token');

      // silent login
      params.set('prompt', 'none');

      const nonce = this.nonceService.generate();
      params.set('nonce', nonce);
      params.set('state', nonce);

      this.logger.debug('Building authorization URL');
      const url = this.authOptions.authUrl + '?' + params;

      if (this.authRefreshComponent) {
        return this.authRefreshComponent.refreshToken(url).pipe(
          take(1),
          retryWhen((errors) =>
            errors.pipe(
              tap(
                (_) => {},
                (error) => this.logger.error('Error refreshing identity token due to {0}, will try again', error)
              ),
              delay(5000),
              take(2),
              concat(throwError(new Error('Tried 3 times but unable to refresh identity token')))
            )
          ),
          switchMap((hash) =>
            this.processAuthInfo(hash, nonce).pipe(
              first(),
              switchMap((result) => {
                if (result) {
                  this.refreshTokenSubject.next(this.tokenService.authInfo.idToken);
                  return of(this.tokenService.authInfo.idToken);
                } else {
                  return throwError('Unable to process identity token');
                }
              })
            )
          ),
          tap((_) => {
            this.isRefreshingToken = false;
          }),
          catchError((error) => {
            this.logger.error('Error refreshing identity token', error);

            this.isRefreshingToken = false;
            this.refreshTokenSubject.error('');

            return throwError('Error refreshing identity token');
          })
        );
      } else {
        this.refreshTokenSubject.error('Refresh identity token component not found');
        this.isRefreshingToken = false;

        return throwError('Refresh identity token component not found');
      }
    } else {
      this.logger.debug('Refreshing identity token in progress. Waiting for token.');

      return this.refreshTokenSubject.pipe(filter((token) => token != null));
    }
  }

  public changeOrganization(organizationId: string): void {
    if (organizationId !== this.user.organization) {
      this.user.organization = organizationId;

      const tokenServiceUser = this.tokenService.userProfile;
      tokenServiceUser.organization = organizationId;
      this.tokenService.userProfile = tokenServiceUser;

      this.store(this.organizationStorageKey, organizationId);
      //ensure that right data is shown in view
      this.router.navigate(['/']);
    }
  }

  public refreshToken(): Observable<string> {
    if (!this.isRefreshingToken) {
      this.logger.debug('Signing in silently');

      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.refreshTokenSubject = new Subject<string>();

      const params = new URLSearchParams();
      params.set('client_id', this.authOptions.clientId);
      params.set('scope', this.authOptions.scope);
      params.set('redirect_uri', this.authOptions.redirectSilentUrl);
      params.set('response_type', this.authOptions.response);

      // silent login
      params.set('prompt', 'none');

      const nonce = this.nonceService.generate();
      params.set('nonce', nonce);
      params.set('state', nonce);

      this.logger.debug('Building authorization URL');
      const url = this.authOptions.authUrl + '?' + params;

      if (this.authRefreshComponent) {
        return this.authRefreshComponent.refreshToken(url).pipe(
          take(1),
          retryWhen((errors) =>
            errors.pipe(
              tap(
                (_) => {},
                (error) => this.logger.error('Error refreshing token due to {0}, will try again', error)
              ),
              delay(5000),
              take(2),
              concat(throwError(new Error('Tried 3 times but unable to refresh token')))
            )
          ),
          switchMap((hash) =>
            this.processAuthInfo(hash, nonce).pipe(
              first(),
              switchMap((result) => {
                if (result) {
                  this.refreshTokenSubject.next(this.tokenService.authInfo.accessToken);
                  return of(this.tokenService.authInfo.accessToken);
                } else {
                  return throwError('Unable to process refresh token');
                }
              })
            )
          ),
          tap((_) => {
            this.isRefreshingToken = false;
          }),
          catchError((error) => {
            this.logger.error('Error refreshing token', error);

            this.isRefreshingToken = false;
            this.refreshTokenSubject.error('Error refreshing token');

            return throwError('Error refreshing token');
          })
        );
      } else {
        this.refreshTokenSubject.error('Refresh token component not found');
        this.isRefreshingToken = false;

        return throwError('Refresh token component not found');
      }
    } else {
      this.logger.debug('Refreshing token in progress. Waiting for token.');

      return this.refreshTokenSubject.pipe(filter((token) => token != null));
    }
  }

  public callback(): Observable<void> {
    this.logger.debug('Callback called. Handling auth info');

    let hash = window.location.hash.substring(1); //remove # from beginning

    if (environment.uwp) {
      //in UWP remove router path from beginning of hash
      hash = hash.substring('/auth/callback?'.length);
    }

    const nonce = this.nonceService.getNonce('login');

    return this.processAuthInfo(hash, nonce).pipe(
      map((result) => {
        if (result) {
          //remove hash from browser history
          history.replaceState({}, document.title, location.href.substr(0, location.href.length - location.hash.length));
        }

        if (!this.tokenService.authInfo.idToken) {
          this.refreshIdentityToken().pipe(first()).subscribe();
        }

        AppAnalytics.setUserId(this.user?.id);

        this.startupService.finished('auth');

        const existingUrl = localStorage.getItem(this.storageKeys.existingUrl);

        if (existingUrl) {
          this.logger.debug('Redirecting to existing url', existingUrl);

          localStorage.removeItem(this.storageKeys.existingUrl);

          this.router.navigateByUrl(existingUrl);
        } else {
          this.logger.debug('Redirecting to root');
          this.router.navigate(['/']);
        }
      }),
      switchMap((_) => this.userNotificationsService.init()),
      mapTo(null)
    );
  }

  public init(): Observable<boolean> {
    this.logger.debug('Initializing');

    if (window.location.href.indexOf('/auth/callback') > -1) {
      this.logger.debug('Initialized from auth callback, skipping.');
      return of(false);
    }
    const user = this.tokenService.userProfile;

    if (this.isTokenValid()) {
      this.logger.debug('Loading cached user profile');

      this.user = user;
      if (this.user?.id) {
        AppAnalytics.setUserId(this.user.id);
      }

      this.logger.debug('this.user.roles', this.user.roles);

      this.logger.debug('Access token expires at', new Date(this.tokenService.authInfo.expiresAt));

      this.initRefreshToken();

      this.startupService.finished('auth');

      return of(true);
    } else {
      this.preLoginClearing();
      this.login();

      return of(false);
    }
  }

  public logout(): void {
    this.logger.debug('Signing out');

    const params = new URLSearchParams();
    params.set('id_token_hint', this.tokenService.authInfo.idToken);
    params.set('post_logout_redirect_uri', this.authOptions.redirectLogoutUrl);

    //clear stored data
    this.tokenService.clear();

    this.translationService.clear();

    this.logger.debug('Redirecting to de-authorization URL');
    window.location.href = this.authOptions.deauthUrl + '?' + params;
  }

  public editProfile(): void {
    this.logger.debug('Redirecting to edit profile');

    const params = new URLSearchParams();
    const href = window.location.href;

    params.set('redirect_uri', href);

    this.logger.debug('Redirecting to self-care URL');
    window.location.href = this.authOptions.selfcareUrl + '?' + params;
  }

  public getUserInitials(): string {
    let result = '';

    if (this.user) {
      if (this.user.givenName) {
        result += this.user.givenName[0];
      }
      if (this.user.familyName) {
        result += this.user.familyName[0];
      }
    }

    return result;
  }

  public isReader(): boolean {
    return this.hasRole(Role.customerReader);
  }

  public isCustomer(): boolean {
    return (
      this.hasRole(Role.customerAdministrator) ||
      this.hasRole(Role.customerEngineer) ||
      this.hasRole(Role.customerReader) ||
      this.hasRole(Role.customerImplementer) ||
      this.hasRole(Role.customerManufacturer)
    );
  }

  public isProvider(): boolean {
    return this.hasRole(Role.administrator) || this.hasRole(Role.engineer);
  }

  public isEngineer(): boolean {
    return this.hasRole(Role.engineer) || this.hasRole(Role.customerEngineer);
  }

  public isPowerEngineer(): boolean {
    return this.hasRole(Role.powerEngineer);
  }

  public isProjectEngineer(): boolean {
    return this.hasRole(Role.customerManufacturer);
  }

  public isAdministrator(): boolean {
    return this.hasRole(Role.administrator) || this.hasRole(Role.customerAdministrator);
  }

  public isProviderAdministrator(): boolean {
    return this.hasRole(Role.administrator);
  }

  public isCustomerAdministrator(): boolean {
    return this.hasRole(Role.customerAdministrator);
  }

  public isOnlyCustomerAdministrator(): boolean {
    return this.hasOnlyGivenRole(Role.customerAdministrator);
  }
  public isCustomerEngineer(): boolean {
    return this.hasRole(Role.customerEngineer);
  }

  public isCustomerImplementer(): boolean {
    return this.hasRole(Role.customerImplementer);
  }

  public isCustomerManufacturer(): boolean {
    return this.hasRole(Role.customerManufacturer);
  }

  public isEngineerOrCustomerEngineer(): boolean {
    return [Role.engineer, Role.powerEngineer, Role.customerEngineer].some((role) => this.hasRole(role));
  }

  public isProviderEngineer(provider?: AuthProvider): boolean {
    return this.hasRole(Role.engineer) && (provider ? this.hasOrganization(provider.id) : true);
  }

  public isProviderPowerEngineer(provider?: AuthProvider): boolean {
    return this.hasRole(Role.powerEngineer) && (provider ? this.hasOrganization(provider.id) : true);
  }

  public isInternalEngineer(): boolean {
    return this.hasRole(Role.engineer);
  }

  public isRestricted(): boolean {
    return this.user && this.user.organization && (this.user.division != null || this.user.site != null);
  }

  public applyAccessTokenHeaderValue(options: { [key: string]: any }): void {
    options['authToken'] = 'Bearer ' + this.tokenService.authInfo.accessToken;
  }

  public hasRole(role: string): boolean {
    return this.user && this.user.roles && this.user.roles.includes(role);
  }

  //method to check if user has at least one role from the inputted string array input
  public hasAtLeastOneOfGivenRoles(givenRoles: string[]): boolean {
    return this.user && this.user.roles && this.user.roles.some((role) => givenRoles.includes(role));
  }

  public hasOnlyGivenRole(role: string): boolean {
    return this.user && this.user.roles && this.user.roles.length === 1 && this.user.roles[0] === role;
  }

  protected store(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  protected read(key: string): string {
    return sessionStorage.getItem(key);
  }

  protected delete(key: string): void {
    sessionStorage.removeItem(key);
  }

  protected processAuthInfo(url: string, nonce: string): Observable<boolean> {
    this.logger.debug('Processing auth info');

    const params: { [key: string]: string } = {};

    if (url && url !== '') {
      url.split('&').forEach((param) => {
        const keyValue = param.split('=');
        params[keyValue[0]] = keyValue[1];
      });
    }

    const error = params['error'];
    const accessToken = params['access_token'];
    const idToken = params['id_token'];

    if (error) {
      this.logger.error('Authorization error {0} due to {1}', error, params['error_description']);

      if (error === 'login_required') {
        this.preLoginClearing();
        this.login();
        return of(null);
      } else {
        return of(false);
      }
    } else if (accessToken) {
      this.logger.debug('Found auth info');

      const nonceFound = params['state'];

      if (nonce !== nonceFound) {
        this.logger.error('Nonce differs');
        return of(false);
      }

      const expiresAt = Date.now() + Number(params['expires_in']) * 1000;

      this.tokenService.authInfo = {
        accessToken: params['access_token'],
        idToken,
        tokenType: params['token_type'],
        expiresAt,
        scope: params['scope']
      };

      const decoded = this.tokenService.decodeToken(params['access_token']);

      this.logger.debug('Access token decoded', decoded);

      let organizations = decoded['mcomplus.organization'];
      if (!Array.isArray(organizations)) {
        const organization = organizations;
        organizations = [];
        organizations.push(organization);
      }

      const user = {
        id: decoded['sub'],
        name: decoded['given_name'] + ' ' + decoded['family_name'],
        givenName: decoded['given_name'],
        familyName: decoded['family_name'],
        email: decoded['email'],
        roles: Array.isArray(decoded['role']) ? decoded['role'] : [decoded['role']],
        organizations,
        organization: this.getToggledOrganization(organizations),
        provider: decoded['mcomplus.provider'],
        division: decoded['mcomplus.division'],
        site: decoded['mcomplus.site']
      };

      this.tokenService.userProfile = user;
      this.user = user;

      this.logger.debug('Auth finished. Access token expires at', new Date(expiresAt));

      this.initRefreshToken();
    } else if (idToken) {
      this.logger.debug('Found identity token');

      const nonceFound = params['state'];

      if (nonce !== nonceFound) {
        this.logger.error('Nonce differs');
        return of(false);
      }

      this.tokenService.authInfo = Object.assign({}, this.tokenService.authInfo, { idToken });
    } else {
      this.logger.error('Auth info not found.');
    }

    return of(true);
  }

  protected login(): void {
    this.logger.debug('Signing in');

    const params = new URLSearchParams();
    params.set('client_id', this.authOptions.clientId);
    params.set('scope', this.authOptions.scope);
    params.set('redirect_uri', this.authOptions.redirectUrl);
    params.set('returnUrl', this.authOptions.redirectUrl);
    params.set('response_type', this.authOptions.response);

    const nonce = this.nonceService.generate('login');
    params.set('nonce', nonce);
    params.set('state', nonce);

    this.logger.debug('Redirecting to authorization URL');
    window.location.href = this.authOptions.authUrl + '?' + params;
  }

  protected isTokenValid(): boolean {
    const expiresAt = this.tokenService.authInfo.expiresAt;
    const isDesktop = environment.uwp === true;
    let result = Date.now() < expiresAt;

    if (!result && isDesktop && !navigator.onLine) {
      this.logger.debug('Is desktop and is offline, forcing isTokenValid to true');
      result = true;
    }

    if (result) {
      this.logger.debug('Token is valid');
    } else {
      this.logger.debug('Token is NOT valid');
    }

    return result;
  }

  private hasOrganization(organization: string): boolean {
    return this.user && this.user.organization && this.user.organization === organization;
  }

  private relogin(): Observable<boolean> {
    if (!this.isRelogin) {
      this.logger.debug('Processing relogin');

      this.isRelogin = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.reloginSubject = new Subject<boolean>();

      const params = new URLSearchParams();
      params.set('client_id', this.authOptions.clientId);
      params.set('scope', this.authOptions.scope);
      params.set('redirect_uri', this.authOptions.redirectReloginUrl);
      params.set('response_type', this.authOptions.response);

      const nonce = this.nonceService.generate();
      params.set('nonce', nonce);
      params.set('state', nonce);

      this.logger.debug('Building authorization URL');
      const url = this.authOptions.authUrl + '?' + params;

      if (this.authReloginComponent) {
        return this.authReloginComponent.relogin(url).pipe(
          take(1),
          switchMap((hash) =>
            this.processAuthInfo(hash, nonce).pipe(
              first(),
              switchMap((result) => {
                if (result) {
                  this.reloginSubject.next(true);
                  return of(true);
                } else {
                  return throwError('Error processing auth info');
                }
              })
            )
          ),
          catchError((error) => {
            this.logger.error('Error processing relogin', error);
            this.reloginSubject.error('Error processing relogin');

            return throwError('Error processing relogin');
          }),
          finalize(() => {
            this.isRelogin = false;
          })
        );
      } else {
        this.logger.error('Relogin component not found');
        this.reloginSubject.error('Relogin component not found');

        return throwError('Relogin component not found');
      }
    } else {
      this.logger.debug('Relogin in progress. Waiting for result.');

      return this.reloginSubject.pipe(filter((result) => result != null));
    }
  }

  private initRefreshToken(): void {
    this.logger.debug('Initializing refresh token');

    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
      this.refreshTokenTimeout = null;
    }

    const expiresAt = this.tokenService.authInfo.expiresAt;
    let refreshIn = expiresAt - Date.now();

    // Access Token cannnot be refreshed when it is already expired,
    // try to refresh token 120s before expiration
    if (refreshIn - 120 * 1000 > 0) {
      refreshIn = refreshIn - 120 * 1000;

      this.logger.debug('Will refresh access token at', new Date(Date.now() + refreshIn));

      this.refreshTokenTimeout = setTimeout(() => {
        this.logger.debug('Refreshing access token');
        this.refreshToken()
          .pipe(first())
          .subscribe(
            (_) => {
              if (!this.tokenService.authInfo.idToken) {
                this.refreshIdentityToken().pipe(first()).subscribe();
              }
            },
            (error) => {
              this.logger.error('Error refreshing access token', error);
            }
          );
      }, refreshIn);
    } else if (refreshIn > 0) {
      //less then 120s to expiration
      this.logger.debug('Token expires in less then 2 minutes, refreshing now');
      this.refreshToken()
        .pipe(first())
        .subscribe(
          (_) => {
            if (!this.tokenService.authInfo.idToken) {
              this.refreshIdentityToken().pipe(first()).subscribe();
            }
          },
          (error) => {
            this.logger.error('Error refreshing access token', error);
          }
        );
    } else {
      this.logger.debug('Token is expired, will not refresh');
    }
  }

  private getToggledOrganization(organizations: Array<string>): string {
    const toggledOrganization = this.read(this.organizationStorageKey);
    if (toggledOrganization === undefined || toggledOrganization === null || toggledOrganization === '') {
      const firstOrganization = organizations[0];
      this.store(this.organizationStorageKey, firstOrganization);
    }

    return this.read(this.organizationStorageKey);
  }

  private preLoginClearing(): void {
    this.logger.debug('Clearing stored user info');
    this.tokenService.clear();

    const pathname = window.location.pathname;

    if (pathname && pathname !== '/' && pathname !== '/index.html') {
      // '/index.html' is in UWP, whre we do not want to store path
      this.logger.debug('Storing url for redirect after login', pathname);
      localStorage.setItem(this.storageKeys.existingUrl, pathname);
    }
  }
}
