import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { Notification } from '../model/Notification';
import { NotificationType } from '../model/NotificationType';
import { NotificationsChannel } from './notifications-channel';
import { AuthService } from '../../../auth/shared/auth.service';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

@Injectable()
export class NotificationsService {

  private notifications: Subject<Notification> = new Subject<Notification>();;
  private dismissedNotifications: Subject<Notification> = new Subject<Notification>();;
  private channels: { [key: string]: NotificationsChannel } = {};

  constructor(
    private authService: AuthService
  ) { }

  public getNotifications(): Observable<Notification> {
    return this.notifications;
  }

  public getDismissedNotifications(): Observable<Notification> {
    return this.dismissedNotifications;
  }

  public getChannel(name: string): NotificationsChannel {
    if (!this.channels[name]) {
      this.channels[name] = new NotificationsChannel(this.notifications, this.dismissedNotifications, this.authService);
    }

    return this.channels[name];
  }

  public success(message: string): Notification;
  public success(message: string, autoDismiss: boolean): Notification;
  public success(message: string, autoDismiss: boolean, context: string): Notification;
  public success(message: string, autoDismiss?: boolean, context?: string): Notification {
    const notification = {
      type: NotificationType.SUCCESS,
      text: message,
      autoDismissTime: autoDismiss ? 5000 : 0,
      dismissable: true,
      context: context,
      dismissed: null,
      dismiss: () => this.remove(notification)
    };

    return this.create(notification);
  }

  public info(message: string): Notification;
  public info(message: string, autoDismiss: boolean): Notification;
  public info(message: string, autoDismiss: boolean, context: string): Notification;
  public info(message: string, autoDismiss?: boolean, context?: string): Notification {
    const notification = {
      type: NotificationType.INFO,
      text: message,
      autoDismissTime: autoDismiss ? 5000 : 0,
      dismissable: true,
      context: context,
      dismissed: null,
      dismiss: () => this.remove(notification)
    };

    return this.create(notification);
  }

  public warning(message: string): Notification;
  public warning(message: string, autoDismiss: boolean): Notification;
  public warning(message: string, autoDismiss: boolean, context: string): Notification;
  public warning(message: string, autoDismiss?: boolean, context?: string): Notification {
    const notification = {
      type: NotificationType.WARNING,
      text: message,
      autoDismissTime: autoDismiss ? 5000 : 0,
      dismissable: true,
      context: context,
      dismissed: null,
      dismiss: () => this.remove(notification)
    };

    return this.create(notification);
  }

  public danger(message: string): Notification;
  public danger(message: string, details: string): Notification;
  public danger(message: string, details: string, autoDismiss: boolean): Notification;
  public danger(message: string, details: string, autoDismiss: boolean, context: string): Notification;
  public danger(message: string, details?: string, autoDismiss?: boolean, context?: string): Notification {
    const notification = {
      type: NotificationType.DANGER,
      text: message,
      autoDismissTime: autoDismiss ? 5000 : 0,
      dismissable: true,
      context: context,
      dismissed: null,
      details: this.enrichDetails(message, details),
      dismiss: () => this.remove(notification)
    };

    return this.create(notification);
  }

  private remove(notification: Notification): void {
    this.dismissedNotifications.next(notification);
  }

  private create(notification: Notification): Notification {
    this.notifications.next(notification);
    return notification;
  }

  private enrichDetails(message: string, details: string): string {
    const timestamp = moment().format();
    const location = window.location.href;
    const env = window.navigator.userAgent;
    const user = this.authService.user.id;
    const version = environment.version;
    
    return `Timestamp: ${timestamp}\nMessage:${message}\n\nDetails: ${JSON.stringify(details)}\n\nVersion: ${version}\nUser: ${user}\nEnvironment: ${env}\nLocation: ${location}`;
  }
}
